html, body {
  height: 100%;
  background: #fbfbfb;
  margin: 0;
  font-family: Comfortaa;
}

div#app {
  min-height: 100%;
  flex-direction: column;
  display: flex;
}

/*# sourceMappingURL=index.faaf480c.css.map */
