html,
body {
  font-family: "Comfortaa";
  height: 100%;
  margin: 0;
  background: rgb(251, 251, 251);
}

div#app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}
